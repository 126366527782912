
	import { Component, InjectReactive, Prop, Mixins, Watch } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import ProItemTag from '@pixcap/ui-core/components/common/ProItemTag.vue';
	import IconInfo from '@pixcap/ui-library/components/Icons/IconInfo.vue';
	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE, PixcapLibraryFile } from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import { appUtilities } from '@/modules/appUtilities';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		SUBSCRIPTION_UPGRADE_CASE,
		UPGRADE_PLAN_TRIGGER_TYPE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { mapGetters, mapState } from 'vuex';
	import AnimatedThumbnail from '@/components/pixcap-library/shared/AnimatedThumbnail.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user/wrapper';
	import ItemOpenAction from '@/components/pixcap-library/shared/ItemOpenAction.vue';
	import ItemCopyAction from '@/components/pixcap-library/shared/ItemCopyAction.vue';

	@Component({
		name: 'PixcapLibraryItem',
		components: {
			ItemCopyAction,
			ItemOpenAction,
			AnimatedThumbnail,
			CaptionText,
			ProItemTag,
			BodyText,
			IconInfo,
		},
		computed: {
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
			}),
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
			...mapState(LIBRARY_NAMESPACE, {
				selectedLibraryItemSlug: (state: IAssetsLibraryState) => state.selectedLibraryItemSlug,
			}),
		},
	})
	export default class PixcapLibraryItem extends Mixins(AuthenticationModalMixin) {
		@Prop() asset: PixcapLibraryFile;
		@Prop() packId: string;

		personalData: UserState['personalData'];
		selectedLibraryItemSlug: IAssetsLibraryState['selectedLibraryItemSlug'];
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;

		LIBRARY_RESOURCE_TYPE = LIBRARY_RESOURCE_TYPE;

		@InjectReactive() isDesktopScreen: boolean;

		showAnimation = true;

		get isCharacterType() {
			return this.asset.resourceType?.toLowerCase() === LIBRARY_RESOURCE_TYPE.CHARACTERS;
		}

		get isPurchased() {
			return this.personalData?.user?.purchaseHistory?.includes(this.asset.itemId);
		}

		get isOwned() {
			if (this.isProOrAbove) return true;
			return this.personalData?.user?.purchaseHistory?.includes(this.asset.itemId);
		}

		get displayedThumbnailUrl() {
			return this.asset.thumbnailUrl?.url;
		}

		get thumbnailAltTag() {
			const altTag = `${this.asset.itemName} 3D Graphic`;
			return altTag;
		}

		get thumbnailTitleTag() {
			return `Download ${this.thumbnailAltTag} in PNG, GLB for graphic design projects`;
		}

		handleMouseLeave() {
			if (this.$route.name !== 'LibraryExploreList' || !this.selectedLibraryItemSlug) this.showAnimation = false;
			else this.showAnimation = true;
		}

		handleOpenItem(e: MouseEvent, url: string) {
			if (e.ctrlKey) return;
			e.preventDefault();
			e.stopPropagation();
			const currentItemIndex = window.history.state?.currentItemIndex == null ? -1 : window.history.state?.currentItemIndex - 1;
			window.history.pushState({ currentItemIndex }, '', url);
			LibraryMutations.setSelectedLibraryItemSlug(this.$store, this.asset.slug);
		}

		_checkUserPermission(isMustPro = false, triggerReason?: string) {
			if (isMustPro) {
				if (!this.isOwned) {
					UserMutations.setUpgradeModalState(this.$store, {
						triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM,
					});
					return true;
				}
			}
			return false;
		}

		handleRemixScene() {
			if (this.asset.isPro && !this.isAuthenticated) {
				this.showAuthenticationModal();
				return;
			}
			if (this.isAuthenticated && this._checkUserPermission(this.asset.isPro, 'Item Details Edit')) return;
			const query = {
				slug: this.asset.slug,
			};
			ProjectActions.createDesignProject(this.$store, this.$router, query, 'Remix in Mix and Match', {
				'Item Name': this.asset.itemName,
				'Item Slug': this.asset.slug,
				'Item Type': this.asset.itemType,
			});
		}

		created() {
			if (this.$route.name !== 'LibraryExploreList' || !this.selectedLibraryItemSlug) this.showAnimation = false;
		}
	}
