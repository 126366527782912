
	import { Component, InjectReactive, Mixins, Prop } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import ProItemTag from '@pixcap/ui-core/components/common/ProItemTag.vue';
	import {
		Getters as UserGetters,
		GetterTypes as UserGettersTypes,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		SUBSCRIPTION_UPGRADE_CASE,
		UPGRADE_PLAN_TRIGGER_TYPE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { mapGetters, mapState } from 'vuex';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import { PixcapLibraryFile } from '@/models/store/pixcapassets.interface';
	import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { LibraryMockupItemType } from '@/constants/pixcapassets.constants';
	import AnimatedThumbnail from '@/components/pixcap-library/shared/AnimatedThumbnail.vue';
	import ItemOpenAction from '@/components/pixcap-library/shared/ItemOpenAction.vue';
	import ItemCopyAction from '@/components/pixcap-library/shared/ItemCopyAction.vue';

	@Component({
		name: 'PixcapLibraryTemplate',
		components: {
			AnimatedThumbnail,
			ProItemTag,
			BodyText,
			ItemOpenAction,
			ItemCopyAction,
		},
		computed: {
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
				overridingPricingCountryCode: UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE,
				getPricingForUser: UserGettersTypes.GET_PRICING_FOR_USER,
			}),
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
		},
	})
	export default class PixcapLibraryTemplate extends Mixins(AuthenticationModalMixin) {
		@Prop() asset: PixcapLibraryFile;
		@Prop() packId: string;

		personalData: UserState['personalData'];
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;
		overridingPricingCountryCode: ReturnType<UserGetters[UserGettersTypes.OVERRIDING_PRICING_COUNTRY_CODE]>;
		getPricingForUser: ReturnType<UserGetters[UserGettersTypes.GET_PRICING_FOR_USER]>;

		bottomInfoHeight = -50;
		LIBRARY_RESOURCE_TYPE = LIBRARY_RESOURCE_TYPE;
		showAnimation = false;

		@InjectReactive() isDesktopScreen: boolean;

		get isPurchased() {
			return this.personalData?.user?.purchaseHistory?.includes(this.asset.itemId);
		}

		get isOwned() {
			if (this.isProOrAbove) return true;
			return this.personalData?.user?.purchaseHistory?.includes(this.asset.itemId);
		}

		get previewLink() {
			if (this.isMockupTemplate) {
				return { name: 'LibraryMockupDetailPage', params: { itemSlug: this.asset.slug } };
			} else {
				return { name: 'LibraryTemplateDetailPage', params: { itemSlug: this.asset.slug } };
			}
		}

		get isMockupTemplate() {
			const itemType = this.asset.itemType;
			return LibraryMockupItemType.includes(itemType);
		}

		get altText() {
			return `${this.asset.itemName} 3D Template`;
		}

		get thumbnailTitleTag() {
			return `Download ${this.altText} in PNG, GLB for graphic design projects`;
		}

		handleOpenItem(e: MouseEvent, url: string) {
			if (e.ctrlKey) return;
			e.preventDefault();
			e.stopPropagation();
			const currentItemIndex = window.history.state?.currentItemIndex == null ? -1 : window.history.state?.currentItemIndex - 1;
			window.history.pushState({ currentItemIndex }, '', url);
			LibraryMutations.setSelectedLibraryTemplateSlug(this.$store, this.asset.slug);
		}

		_checkUserPermission(isMustPro = false, triggerReason?: string) {
			if (isMustPro) {
				if (!this.isOwned) {
					UserMutations.setUpgradeModalState(this.$store, {
						triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM,
					});
					return true;
				}
			}
			return false;
		}

		handleRemixScene() {
			if (this.asset.isPro && !this.isAuthenticated) {
				this.showAuthenticationModal();
				return;
			}
			if (this.isAuthenticated && this._checkUserPermission(this.asset.isPro, 'Template Details Edit')) return;
			ProjectActions.createDesignProject(this.$store, this.$router, { slug: this.asset.slug }, 'Remix in Mix and Match', {
				'Item Name': this.asset.itemName,
				'Item Slug': this.asset.slug,
				'Item Type': this.asset.itemType,
			});
		}
	}
